<template>
  <PaymentCardBase :title="title" :active="active">
    <template v-slot:body>
      <splide :options="splideOption" @splide:move="onMove">
        <splide-slide
          v-for="(wallet, walletIndex) in wallets"
          :key="walletIndex"
        >
          <WalletCard
            :size="`base`"
            :title-prefix="wallet.currency.symbol"
            :title="wallet.balance"
            :subtitle="`Current Balance`"
            :text-position="`center`"
          />
        </splide-slide>
      </splide>
      <section class="h-8 text-center border">
        <span
          class="text-sm text-center text-gray-500"
          v-if="currentWallet.default"
          v-text="`Default`"
        />
      </section>

      <section>
        <!-- {{ currentWalletData }} -->
        <div class="grid grid-cols-2 border-b divide-x divide-y">
          <div
            v-for="(walletData, walletDataIndex) in currentWalletData"
            :key="walletDataIndex"
            class="flex flex-col items-center justify-center px-2 py-3"
            :class="walletDataIndex === 0 ? 'border-t border-l' : ''"
          >
            <span class="text-xs text-gray-500">
              {{ walletData.label }}
            </span>
            <span class="font-medium text-gray-700">
              {{ walletData.value }}
            </span>
          </div>
          <!-- <div class="flex flex-col items-center justify-center px-2 py-3">
            <span class="text-xs text-gray-500">Total Debit</span>
            <span class="font-medium text-gray-700">$50</span>
          </div> -->
        </div>

        <!-- {{ currentWallet }} -->
      </section>

      <section
        class="flex flex-col items-center justify-center w-full py-4 mb-4 lg:flex-row lg:gap-x-3 gap-y-3 lg:gap-y-0"
      >
        <AnchorButton
          :text="`Deduct balance`"
          :width="`10rem`"
          :variant="`warning`"
          :isLoading="false"
          @click="$emit('deductBalance')"
        />

        <AnchorButton
          :text="`Add balance`"
          :width="`10rem`"
          :variant="`green`"
          :isLoading="false"
          @click="$emit('addBalance')"
        />
      </section>
    </template>
  </PaymentCardBase>
</template>

<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide'
import '@splidejs/splide/dist/css/themes/splide-default.min.css'

export default {
  name: 'PaymentCardWalletBlock',
  components: {
    Splide,
    SplideSlide,
    WalletCard: () => import('@/components/cards/WalletCard.vue'),
    // FeatureListTypography: () =>
    //   import('@/components/typography/FeatureListTypography.vue'),
    PaymentCardBase: () => import('./PaymentCardBaseBlock'),
    AnchorButton: () => import('@/components/form/AnchorButton.vue'),
  },
  props: {
    title: {
      type: [String, Number],
      required: false,
    },
    active: {
      type: Boolean,
      required: false,
      default: false,
    },
    wallets: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      splideOption: {
        rewind: true,
        width: '100%',
        fixedWidth: '100%',
        perPage: 1,
        // gap: "1rem"
        /*  padding: {
          right: "0.25rem",
          left: "0rem"
        } */
      },

      currentSlide: 0,
    }
  },

  computed: {
    currentWallet() {
      return this.wallets[this.currentSlide]
    },
    currentWalletData() {
      const data = [],
        c = [],
        d = []

      const currency = this.currentWallet.currency.symbol
      const credits = this.currentWallet.credits
      const debits = this.currentWallet.debits

      c.push(
        {
          label: 'Total Credits',
          value: currency + this.currentWallet.total_credit_amount,
        },
        {
          label: 'Positive Balance Adjustment',
          value: currency + credits.positive_balance_adjustment,
        },
        {
          label: 'Card Topup',
          value: currency + credits.card_topup,
        },
        {
          label: 'Agent Topup',
          value: currency + credits.agent_topup,
        },
        {
          label: 'Refunded',
          value: currency + credits.refunded,
        },
        {
          label: 'Referral Bonus',
          value: currency + credits.referral_bonus,
        },
        {
          label: 'Signup Bonus',
          value: currency + credits.sign_up_bonus,
        },
        {
          label: 'Trigger Payment',
          value: currency + credits.trigger_payment,
        }
      )

      d.push(
        {
          label: 'Total Debits',
          value: currency + this.currentWallet.total_debit_amount,
        },
        {
          label: 'Negative Balance Adjustment',
          value: currency + debits.negative_balance_adjustment,
        },
        {
          label: 'Card Refunded',
          value: currency + debits.card_refunded,
        },
        {
          label: 'Trips',
          value: currency + debits.trip_payment_through_wallet,
        }
      )

      if (c.length > d.length) {
        console.log('filling')
        const diff = c.length - d.length
        for (let i = 0; i < diff; i++) {
          d.push({
            label: '',
            value: '',
          })
        }
      }

      // combine arrays in order of c.0 -> d.0 -> c.1 -> d.1 -> c.2 -> d.2
      for (let i = 0; i < c.length; i++) {
        data.push(c[i])
        data.push(d[i])
      }

      return data
    },
  },

  methods: {
    onMove(_e, newIndex) {
      // console.log('onMove', newIndex)
      this.currentSlide = newIndex
    },
  },
}
</script>

<style lang="scss" scoped></style>
